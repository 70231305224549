import React from 'react';
import { GenericComplete } from './GenericComplete';
import Header from '../../../components/ServiceUI/Core/Header/Header';
import content from './content/DSAS.json';

export const DSASCompletePage = () => {
  return (
    <>
      <Header {...content.header} bg="orange.standard" />
      <GenericComplete
        mainHeading="You have consented to data sharing"
        content="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. "
      />
    </>
  );
};
