import React from 'react';
import { SubContainer } from '../../../components/ServiceUI/Core/Layout';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import InfoBox from '../../../components/ServiceUI/Core/InfoBox/InfoBox';
import { Success } from '../../../components/ServiceUI/Core/icons/Outline';
import sanitizeHtml from 'sanitize-html';
import { Link } from 'react-router-dom';
interface GenericCompleteProps {
  mainHeading: string;
  content: string;
}

export const GenericComplete: React.FC<GenericCompleteProps> = ({ mainHeading, content }) => {
  return (
    <SubContainer>
      <Box py="1.5rem">
        <Box height={{ lg: '4rem', base: '3rem' }}>
          <Success height="100%" />
        </Box>
        <Heading as="h2" fontSize={{ lg: 'xl', base: 'sm' }} fontWeight="medium" mt="1rem" mb="2rem">
          {mainHeading}
        </Heading>
        <InfoBox>
          <Box
            fontWeight="light"
            data-testid="description"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content),
            }}
          />
        </InfoBox>
        <Flex justifyContent="flex-end" mt="1rem">
          <Button
            variant="primary"
            as={Link}
            to="/serviceconnect/Profile#consentmanagement"
            w={{ lg: 'auto', base: '100%' }}
          >
            Back
          </Button>
        </Flex>
      </Box>
    </SubContainer>
  );
};
