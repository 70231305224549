import { appConstants } from '../../../../../helper/client/constant';
import { request } from '../../../../../helper/useAxios';

export const submit = (data, caseID) => {
  const payload = {
    content: {
      ConsentPrivacyNote: 'true',
      ConsentProvided: data.ConsentProvided,
    },
    pageInstructions: [
      {
        instruction: 'UPDATE',
        target: '.UserProfile',
        content: {
          EmployeeID: data.UserProfileEmployeeID,
          GivenNames: data.UserProfileGivenNames,
          Surname: data.UserProfileSurname,
          DateOfBirth: data.DateOfBirth.replaceAll('-', ''),
        },
      },
      {
        instruction: 'UPDATE',
        target: '.UserProfile.Email(1)',
        content: {
          Address: data['UserProfileEmail(1)Address'],
        },
      },
    ],
  };
  return request(`${appConstants.API_ASSIGNMENTS}${caseID}?actionID=CaptureConsents`, 'post', { data: payload });
};
