import React, { useEffect, useState } from 'react';
import SubHeader from '../../components/ServiceUI/Core/SubHeader/SubHeader';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { appConstants } from '../../helper/client/constant';
import content from './Content/Profile.json';
import ProfileContent from '../../components/LandingPage/ProfileContent/ProfileContent';
import { BaseInductions } from '../../components/ServiceUI/LandingPage/BaseInductions/BaseInductions';
import { ConsentManagementDashboard } from '../../components/ServiceUI/LandingPage/ConsentManagement/ConsentManagementDashboard/ConsentManagementDashboard';
import { SubCTA } from '../../components/ServiceUI/LandingPage/SubCTA/SubCTA';
import { Box } from '@chakra-ui/react';
import { getTravelURL } from '../../helper/getOtherAppURL';
import { useDispatch } from 'react-redux';
import { request } from '../../helper/useAxios';
import { setProfile } from '../../reducers';

export const ProfilePage = (props) => {
  const [profileDetails, setprofileDetails] = useState<any>({
    givenName: '',
    surname: '',
    employeeId: '',
    email: '',
    phoneNumber: '',
    mobileNumber: '',
    pmkeys: '',
    allowAnalyticsTracking: '',
    odsId: '',
    service: '',
    group: '',
    rank: '',
    division: '',
    dob: '',
    contactNumber: [],
    address: [],
    contactEmail: [],
    locationDetails: [],
    homeBaseName: '',
    homeBaseState: '',
    homeBaseAddress: '',
    altBaseName: '',
    altBaseState: '',
    altBaseAddress: '',
    altBaseStartDate: '',
    altBaseEndDate: '',
    workingLocBaseName: '',
    workingLocBaseAddress: '',
    workingLocBaseState: '',
    idp: '',
    myGovIDLink: {},
    basesInducted: [],
    hasTravel: '',
  });

  const updateFlagtoHomePage = () => {
    props.onComingtoHomePageFromUpdate();
  };
  const GetUserProfileDetails = () => {
    const alternateBaseLoc = props.profileData.alternateBaseLocation;
    if (alternateBaseLoc.name === appConstants.PROFILE_LOCSELECT) {
      alternateBaseLoc.name = '';
    }
    const locArray = [props.profileData.location, alternateBaseLoc];
    setprofileDetails((prevState) => {
      return {
        ...prevState,
        email: props.profileData.userID,
        employeeId: props.profileData.employeeId,
        pmkeys: props.profileData.pmKeyS,
        allowAnalyticsTracking: props.profileData.AllowAnalyticsTracking,
        odsId: props.profileData.ods,
        givenName: props.profileData.givenNames,
        surname: props.profileData.surname,
        rank: props.profileData.rank,
        service: props.profileData.service,
        group: props.profileData.group,
        division: props.profileData.division,
        dob: props.profileData.dateOfBirth,
        contactNumber: props.profileData.phone,
        address: props.profileData.address,
        locationDetails: locArray,
        homeBaseName: props.profileData.location.name,
        homeBaseState: props.profileData.location.state,
        homeBaseAddress: props.profileData.location.fullAddress,
        altBaseName: props.profileData.alternateBaseLocation.name,
        altBaseState: props.profileData.alternateBaseLocation.state,
        altBaseStartDate:
          props.profileData.alternateLocStartDate === '1970-01-01' ? '' : props.profileData.alternateLocStartDate,
        altBaseEndDate:
          props.profileData.alternateLocEndDate === '1970-01-01' ? '' : props.profileData.alternateLocEndDate,
        altBaseAddress: props.profileData.alternateBaseLocation.fullAddress,
        workingLocBaseName: props.profileData.workingLocation.name,
        workingLocBaseAddress: props.profileData.workingLocation.fullAddress,
        workingLocBaseState: props.profileData.workingLocation.state,
        idp: props.profileData.idp,
        myGovIDLink: props.profileData.myGovIDLink,
        basesInducted: props.profileData.basesInducted,
        hasTravel: props.profileData.myServices.hasTravel,
      };
    });

    if (props.profileData?.phone?.length !== 0) {
      let mobileNumberTemp = props.profileData?.phone?.find((item) => {
        if (item.type === appConstants.PROFILE_PHONE_TYPE_MOBILE) {
          return item;
        }
        return null;
      });
      if (mobileNumberTemp) {
        setprofileDetails((prevState) => {
          return {
            ...prevState,
            mobileNumber: mobileNumberTemp.number,
          };
        });
      }

      let phoneNumberTemp = props.profileData?.phone?.find((item) => {
        if (item.type === appConstants.PROFILE_PHONE_TYPE_WORK || item.type === appConstants.PROFILE_PHONE_TYPE_HOME) {
          return item;
        }
        return null;
      });
      if (phoneNumberTemp) {
        setprofileDetails((prevState) => {
          return {
            ...prevState,
            phoneNumber: phoneNumberTemp.number,
          };
        });
      }
    }
  };

  const dispatch = useDispatch();
  const updateProfile = () => {
    request(appConstants.API_USER_PROFILE_DETAILS, 'get').then((res) => dispatch(setProfile(res.data)));
  };

  useEffect(() => {
    GetUserProfileDetails();
  }, [props.profileData]);

  useEffect(() => {
    document.title = 'Profile';
    updateProfile();
  }, []);

  return (
    <>
      <Header {...content.header} />
      <SubHeader {...content.subheader} />
      <ProfileContent
        personalDetailsTitle={content.personalDetails.personalDetailsTitle}
        personalDetailsDescription={content.personalDetails.personalDetailsDescription}
        locationDetailsTitle={content.personalDetails.locationDetailsTitle}
        locationDetailsDescription={content.personalDetails.locationDetailsDescription}
        givenName={profileDetails.givenName}
        surname={profileDetails.surname}
        email={profileDetails.email}
        phoneNumber={profileDetails.phoneNumber === '00000000' ? '' : profileDetails.phoneNumber}
        mobileNumber={profileDetails.mobileNumber}
        pmkeys={profileDetails.pmkeys ? profileDetails.pmkeys : profileDetails.odsId}
        allowAnalyticsTracking={profileDetails.allowAnalyticsTracking}
        employeeId={profileDetails.employeeId}
        service={profileDetails.service.short}
        rank={profileDetails.rank.short}
        group={profileDetails.group.short}
        workingLocationDesc={content.personalDetails.workingLocationDesc}
        alteratebaseDesc={content.personalDetails.alteratebaseDesc}
        ctaText={content.personalDetails.ctaText}
        workingLocationCtaUrl={content.personalDetails.workingLocationCtaUrl}
        alternativeBaseCtaUrl={content.personalDetails.alternativeBaseCtaUrl}
        homeBaseText={content.personalDetails.homeBaseText}
        homeBaseState={profileDetails.homeBaseState}
        homeBasebaseName={profileDetails.homeBaseName}
        homeBasebaseAddress={profileDetails.homeBaseAddress}
        alternativeBaseText={content.personalDetails.alternativeBaseText}
        alternativeBaseState={profileDetails.altBaseState}
        alternativeBaseName={profileDetails.altBaseName}
        alternativeBaseAddress={profileDetails.altBaseAddress}
        alternativeBaseStartDate={profileDetails.altBaseStartDate}
        alternativeBaseEndDate={profileDetails.altBaseEndDate}
        phoneTitle={content.personalDetails.phoneTitle}
        phoneDescription={content.personalDetails.phoneDescription}
        mobileTitle={content.personalDetails.mobileTitle}
        mobileDescription={content.personalDetails.mobileDescription}
        homeBaseTitle={content.personalDetails.homeBaseTitle}
        homeBaseDescription={content.personalDetails.homeBaseDescription}
        alternativeBaseTitle={content.personalDetails.alternativeBaseTitle}
        alternativeBaseDescription={content.personalDetails.alternativeBaseDescription}
        workingLocBaseName={profileDetails.workingLocBaseName}
        workingLocBaseAddress={profileDetails.workingLocBaseAddress}
        workingLocBaseState={profileDetails.workingLocBaseState}
        onComingtoProfileMainFromUpdate={updateFlagtoHomePage}
        idp={profileDetails.idp}
        myGovIDLink={profileDetails.myGovIDLink}
        hasTravel={profileDetails.hasTravel}
      />
      {props.profileData.myServices.hasTravel && (
        <Box mb="3rem">
          <SubCTA {...content.travelProfileCTA} buttonURL={getTravelURL()} />
        </Box>
      )}
      <ConsentManagementDashboard />
      <BaseInductions basesInducted={props.profileData.basesInducted} {...content.baseInductionContent} />
    </>
  );
};
