import React from 'react';
import { ModalHeader, ModalBody, Text, Flex } from '@chakra-ui/react';
export const DSAS = () => {
  return (
    <>
      <ModalHeader fontSize="lg" fontWeight="medium">
        What is the Data Sharing and Analytics Solution used for?
      </ModalHeader>
      <ModalBody as={Flex} gap={3} flexDirection="column" fontWeight="light">
        <Text>
          This form ensures that your data privacy preferences are recorded and followed by the Department of Defence
          and the Department of Veteran's Affairs.
        </Text>
        <Text>
          Use this form to select to either provide or withdraw your consent for the Department of Defence to collect,
          process and store your data for the specified purpose of the Data Sharing and Analytics Solution.
        </Text>
        <Text>
          The Data Sharing and Analytics Solution links internal Defence health, safety, people and survey data to
          produce meaningful insights about the health, wellbeing and safety of ADF personnel.
        </Text>
        <Text fontWeight="medium">Contacts</Text>
        <Text>Form sponsor: DMFS - SIS</Text>
        <Text>Group: DPG</Text>
      </ModalBody>
    </>
  );
};
