import { request } from '../../../../../helper/useAxios';
import { appConstants } from '../../../../../helper/client/constant';

export const createCase = request(appConstants.API_CASES, 'post', {
  data: {
    caseTypeID: 'DoD-SConn-Work-ConsentManagement',
    content: {
      pyRequestType: 'DSAS',
    },
  },
});

export const getAssignments = (caseID) => request(appConstants.API_ASSIGNMENTS, 'get', { id: caseID });
export const captureConsents = (caseID) =>
  request(appConstants.API_ASSIGNMENTS, 'get', { id: caseID + '/actions/CaptureConsents' }).then((res) => {
    return res.data;
  });
