import { Button, Grid, GridItem, Text } from '@chakra-ui/react';
import React from 'react';
import { PegaCaseBadge } from '../../../Core/Badge/Badge';
import { Consent } from '../types';

interface ConsentManagementItemProps extends Consent {
  start(): void;
}

export const ConsentManagementItem: React.FC<ConsentManagementItemProps> = ({
  title,
  status,
  expiryDate,
  start,
}): JSX.Element => {
  return (
    <Grid
      p="1rem 1.5rem"
      alignItems="center"
      width="100%"
      templateColumns="repeat(4,1fr)"
      borderRadius="0.25rem"
      bg="whispergrey"
      gap={3}
    >
      <GridItem colSpan={{ lg: 1, base: 4 }}>
        <PegaCaseBadge status={status} />
      </GridItem>
      <GridItem colSpan={{ lg: 1, base: 4 }}>
        <Text m={0} fontWeight="medium">
          {title}
        </Text>
      </GridItem>
      <GridItem colSpan={{ lg: 1, base: 4 }}>
        <Text m={0} fontWeight="light">
          {expiryDate?.toDateString() ?? '-'}
        </Text>
      </GridItem>
      <GridItem colSpan={{ lg: 1, base: 4 }}>
        <Button w={{ lg: 'auto', base: '100%' }} float="right" variant="secondary" onClick={start}>
          View
        </Button>
      </GridItem>
    </Grid>
  );
};
