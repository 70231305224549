import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialData/profile.json';

export const handleProfile = createSlice({
  name: 'Handling Profile',
  initialState,
  reducers: {
    setProfile: (_state, { payload }) => {
      return payload;
    },
  },
});

export const { setProfile } = handleProfile.actions;

export default handleProfile.reducer;
