import { Box, Flex, Heading, Link, Text, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { SubContainer } from '../../../Core/Layout';
import { ConsentManagementItem } from './ConsentManagementItem';
import { Consent } from '../types';
import { StartPopup } from './StartPopups/StartPopup';
import { DSAS } from './StartPopups/DSAS';
import { useSelector } from 'react-redux';
import { Profile } from '../../../../../types';

export interface ConsentManagementDashboardProps {}

export interface PanelProps {
  consents: Consent[];
}

export const ConsentManagementDashboard: React.FC<ConsentManagementDashboardProps> = () => {
  const DSASDetails = useSelector((state: { profile: Profile }) =>
    state.profile?.consents.find((consent) => consent.name === 'DSAS'),
  );
  const isCivilian = useSelector((state: { profile: Profile }) => state.profile.service.short === 'Civilian');
  const { isOpen: isStartFormOpen, onToggle } = useDisclosure();

  const [modalContent, setModalContent] = useState<{ Content: React.FC; link: string }>();

  const handleOpen = (Content, link) => {
    onToggle();
    setModalContent({ Content, link });
  };

  const dashboardItems = [
    !isCivilian ? (
      <ConsentManagementItem
        title="DSAS"
        expiryDate={DSASDetails?.expiryDate ? new Date() : undefined}
        status={
          DSASDetails
            ? DSASDetails?.status === 'true'
              ? 'CONSENT-PROVIDED'
              : 'CONSENT-NOT-PROVIDED'
            : 'CONSENT-NOT-PROVIDED'
        }
        start={() => {
          handleOpen(<DSAS />, '/new-consent/DSAS');
        }}
      />
    ) : null,
  ];

  return (
    <Box bg="white" id="consentmanagement">
      <StartPopup isOpen={isStartFormOpen} close={onToggle} content={modalContent} />
      <SubContainer verticalMargin="1rem">
        <Heading as="h3" fontWeight="medium" fontSize="sm">
          Consent management
        </Heading>
        <Text fontWeight="light" mb="1rem">
          To manage your preferences below, you can use the{' '}
          <Link href="/#">**Placeholder: Consent Management Portal**</Link>
        </Text>
        <Flex flexWrap="wrap" gap={3}>
          <>{console.log(dashboardItems)}</>
          {dashboardItems.filter((item) => item).length > 0 ? dashboardItems : <Text>No consents to manage</Text>}
        </Flex>
      </SubContainer>
    </Box>
  );
};
